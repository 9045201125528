@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?75984883');
  src: url('../fonts/fontello.eot?75984883#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?75984883') format('woff2'),
       url('../fonts/fontello.woff?75984883') format('woff'),
       url('../fonts/fontello.ttf?75984883') format('truetype'),
       url('../fonts/fontello.svg?75984883#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?75984883#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-marketing-01:before { content: '\e800'; } /* '' */
.icon-meat_location-01:before { content: '\e801'; } /* '' */
.icon-meat_tehnology-01:before { content: '\e802'; } /* '' */
.icon-heart-empty:before { content: '\e803'; } /* '' */
.icon-mouse-01:before { content: '\e804'; } /* '' */
.icon-pallet-01:before { content: '\e805'; } /* '' */
.icon-part_pig-01:before { content: '\e806'; } /* '' */
.icon-heart:before { content: '\e807'; } /* '' */
.icon-phone_fill-01:before { content: '\e808'; } /* '' */
.icon-phone-01:before { content: '\e809'; } /* '' */
.icon-preservative-free_meat-01:before { content: '\e80a'; } /* '' */
.icon-price-01:before { content: '\e80b'; } /* '' */
.icon-quality-01:before { content: '\e80c'; } /* '' */
.icon-riteil-01:before { content: '\e80d'; } /* '' */
.icon-sausage-shops-01:before { content: '\e80e'; } /* '' */
.icon-security-01:before { content: '\e80f'; } /* '' */
.icon-snowflake-01:before { content: '\e810'; } /* '' */
.icon-subprodukty-01:before { content: '\e811'; } /* '' */
.icon-thermometer-01:before { content: '\e812'; } /* '' */
.icon-traders-01:before { content: '\e813'; } /* '' */
.icon-vip-01:before { content: '\e814'; } /* '' */
.icon-volumes-of-meat-01:before { content: '\e815'; } /* '' */
.icon-application-01:before { content: '\e816'; } /* '' */
.icon-right-open:before { content: '\e817'; } /* '' */
.icon-arrow_left-01:before { content: '\e818'; } /* '' */
.icon-arrow_right:before { content: '\e819'; } /* '' */
.icon-left-open:before { content: '\e81a'; } /* '' */
.icon-calendar-01:before { content: '\e81b'; } /* '' */
.icon-camera-01:before { content: '\e81c'; } /* '' */
.icon-car-01:before { content: '\e81d'; } /* '' */
.icon-clock-01:before { content: '\e81e'; } /* '' */
.icon-contract-01:before { content: '\e81f'; } /* '' */
.icon-element_pig-01:before { content: '\e820'; } /* '' */
.icon-envelope_fill-01:before { content: '\e822'; } /* '' */
.icon-envepole:before { content: '\e823'; } /* '' */
.icon-cancel-1:before { content: '\e824'; } /* '' */
.icon-facebook:before { content: '\e825'; } /* '' */
.icon-cancel-2:before { content: '\e826'; } /* '' */
.icon-fax-01:before { content: '\e827'; } /* '' */
.icon-freezer-01:before { content: '\e828'; } /* '' */
.icon-freshness-01:before { content: '\e829'; } /* '' */
.icon-horeca-01:before { content: '\e82a'; } /* '' */
.icon-insurance-01:before { content: '\e82b'; } /* '' */
.icon-label-01:before { content: '\e82c'; } /* '' */
.icon-linkidin:before { content: '\e82d'; } /* '' */
.icon-location_fill-01:before { content: '\e82e'; } /* '' */
.icon-location-01:before { content: '\e82f'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */